import React from "react";
import { Link } from "react-router";
import { getRelativePath } from "base/shared/Utils";
import {
  CardWrapper,
  CardImage,
  CardContent,
  Price,
  Title,
  PropertyType,
  PriceRow,
  Expenses,
  RoomCardLink,
  PriceLabel,
  ExpensesLabel,
  PriceColumn,
  ExpensesColumn,
  FlatDescription,
} from "./styled-components";

const RoomCard = ({
  id,
  image,
  title,
  subtitle,
  flatDescription,
  priceText,
  url,
}) => {
  const linkUrl = getRelativePath(url);

  return (
    <RoomCardLink
      as={Link}
      data-qa="room-card-link"
      target="_blank"
      to={linkUrl}
    >
      <CardWrapper>
        <CardImage image={image} />
        <CardContent>
          <PropertyType>{subtitle}</PropertyType>
          <FlatDescription>{flatDescription}</FlatDescription>
          <PriceRow>
            <PriceColumn>
              <PriceLabel>Precio</PriceLabel>
              <Price>{priceText}</Price>
            </PriceColumn>
            <ExpensesColumn>
              <ExpensesLabel>Gastos</ExpensesLabel>
              <Expenses>Incluidos</Expenses>
            </ExpensesColumn>
          </PriceRow>
        </CardContent>
      </CardWrapper>
    </RoomCardLink>
  );
};

export default RoomCard;
