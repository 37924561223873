import React from "react";
import { Container } from "reactstrap";
import { Heading, Spacer, Text } from "@badi/badi-components";
import FeaturedCategory from "./featured-category";

// Category mapping
const IMPULSA_CATEGORIES = [
  {
    id: 3,
    title: "Vivienda comunitaria con apoyos permanentes",
    description:
      "Viviendas donde las personas reciben apoyo continuado en las actividades de la vida diaria.",
  },
  {
    id: 1,
    title: "Residencias",
    description:
      "Equipamientos que ofrecen una atención integral que incluye alojamiento, manutención y cuidados.",
  },
  {
    id: 2,
    title: "Vivienda de entrenamiento",
    description:
      "Viviendas donde se aprenden las destrezas necesarias para desenvolverse de manera autónoma.",
  },
  {
    id: 4,
    title: "Vivienda comunitaria con apoyos puntuales",
    description:
      "Viviendas donde las personas reciben apoyos puntuales en las áreas que necesiten.",
  },
];

const FeaturedImpulsa = ({ token }) => {
  return (
    <Container>
      <Spacer top={5} id="featured-impulsa-section">
        <Heading level="32-Bold">Descubre las viviendas disponibles</Heading>
        <Spacer bottom={2}>
          <Text>
            Encuentra la vivienda que mejor se adapte a tus necesidades
          </Text>
        </Spacer>

        {IMPULSA_CATEGORIES.map((category) => (
          <div key={category.id} data-category-id={category.id}>
            <FeaturedCategory
              token={token}
              categoryId={category.id}
              title={category.title}
              description={category.description}
            />
          </div>
        ))}
      </Spacer>
    </Container>
  );
};

export default FeaturedImpulsa;
