import React from "react";
import { getPublicAsset } from "base/shared/Utils";
import { translate } from "base/shared/Localization";
import { Button } from "@badi/badi-components";
import {
  Container,
  Title,
  StepsContainer,
  StepItem,
  StepNumberContainer,
  StepNumber,
  StepContent,
  StepTitle,
  StepDescription,
  StepImageContainer,
  StepImage,
  TimelineLine,
  SearchButton,
} from "./styled-components";

const HowItWorks = ({ onSearch }) => {
  return (
    <Container>
      <Title>{translate("impulsa.how_it_works.title")}</Title>
      <StepsContainer>
        <StepItem position="left">
          <StepContent position="left">
            <StepTitle>
              {translate("impulsa.how_it_works.step1.title")}
            </StepTitle>
            <StepDescription>
              {translate("impulsa.how_it_works.step1.description")}
            </StepDescription>
          </StepContent>
          <StepNumberContainer>
            <StepNumber>1</StepNumber>
          </StepNumberContainer>
          <StepImageContainer>
            <StepImage
              src={getPublicAsset("/images/landings/impulsa/step-1.png")}
            />
          </StepImageContainer>
        </StepItem>

        <TimelineLine />

        <StepItem position="right">
          <StepImageContainer>
            <StepImage
              src={getPublicAsset("/images/landings/impulsa/step-2.png")}
            />
          </StepImageContainer>
          <StepNumberContainer>
            <StepNumber>2</StepNumber>
          </StepNumberContainer>
          <StepContent position="right">
            <StepTitle>
              {translate("impulsa.how_it_works.step2.title")}
            </StepTitle>
            <StepDescription>
              {translate("impulsa.how_it_works.step2.description")}
            </StepDescription>
          </StepContent>
        </StepItem>

        <TimelineLine />

        <StepItem position="left">
          <StepContent position="left">
            <StepTitle>
              {translate("impulsa.how_it_works.step3.title")}
            </StepTitle>
            <StepDescription>
              {translate("impulsa.how_it_works.step3.description")}
            </StepDescription>
          </StepContent>
          <StepNumberContainer>
            <StepNumber>3</StepNumber>
          </StepNumberContainer>
          <StepImageContainer>
            <StepImage
              src={getPublicAsset("/images/landings/impulsa/step-3.png")}
            />
          </StepImageContainer>
        </StepItem>

        <TimelineLine />

        <StepItem>
          <StepImageContainer>
            <StepImage
              src={getPublicAsset("/images/landings/impulsa/step-4.png")}
            />
          </StepImageContainer>
          <StepNumberContainer>
            <StepNumber>4</StepNumber>
          </StepNumberContainer>
          <StepContent position="right">
            <StepTitle>
              {translate("impulsa.how_it_works.step4.title")}
            </StepTitle>
            <StepDescription>
              {translate("impulsa.how_it_works.step4.description")}
            </StepDescription>
          </StepContent>
        </StepItem>
      </StepsContainer>

      <Button onClick={onSearch}>
        {translate("impulsa.main.search_button")}
      </Button>
    </Container>
  );
};

export default HowItWorks;
