import { transparentize } from "polished";
import { gradient, BADI_COLORS, colors, mainColor } from "../constants";

const getGradient = ({ inverted = false, initial, final }) => {
  const direction = inverted ? "0" : "100%";
  const size = inverted ? "150%" : "100%";
  return `linear-gradient(90deg, ${initial} 0%, ${final} 100%) ${direction} 0/ ${size} 100%`;
};

const getBlueGradient = ({ inverted } = {}) => {
  return getGradient({
    initial: "#6d8bff",
    final: colors.blueBooking,
    inverted,
  });
};

export const getGoldenGradient = ({ inverted = false } = {}) => {
  return getGradient({
    initial: "rgb(255, 201, 112)",
    final: "rgb(255, 228, 160)",
    inverted,
  });
};

export const BUTTON_TYPES = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  EXPRESS_BOOKING: "express-booking",
  NEGATIVE: "negative",
  PRIMARY_INVERTED: "primary-inverted",
  GRADIENT: "gradient",
  PREMIUM: "premium",
  PREMIUM_SECONDARY: "premium-secondary",
  GOLD_GRADIENT: "gold-gradient",
  INFO: "info",
};

export const BUTTON_SIZES = {
  REGULAR: "regular",
  SMALL: "small",
};

export const PRIMARY_BUTTON_TYPE = {
  color: colors.white,
  background: mainColor,
  hoverBackground: colors.greenDark,
};

export const PREMIUM_BUTTON_TYPE = {
  color: colors.premiumText,
  background: getGoldenGradient(),
  hoverBackground: getGoldenGradient({ inverted: true }),
};

export const PREMIUM_SECONDARY_BUTTON_TYPE = {
  color: colors.white,
  background: getBlueGradient(),
  hoverBackground: getBlueGradient({ inverted: true }),
};

export const SECONDARY_BUTTON_TYPE = {
  color: colors.darkGrey,
  background: "transparent",
  hoverBackground: "transparent",
  disabledBackground: colors.softGrey,
  border: `1px solid ${colors.softGrey}`,
  hoverBorder: `1px solid ${colors.darkGrey}`,
};

export const EXPRESS_BOOKING_BUTTON_TYPE = {
  color: colors.white,
  background: colors.tertiary,
  hoverBackground: colors.tertiaryHover,
};

export const NEGATIVE_BUTTON_TYPE = {
  color: colors.white,
  background: colors.red,
};

// TODO - delete after remove depency on ExpandedBanner - web
export const PRIMARY_INVERTED_BUTTON_TYPE = {
  color: colors.main,
  background: colors.white,
  border: `1px solid ${colors.white}`,
  hoverBorder: `1px solid ${colors.white}`,
  hoverBackground: transparentize(0.1, colors.white),
};

// TODO - delete after removing dependency on MyProfile
export const GRADIENT_BUTTON_TYPE = {
  color: colors.white,
  background: gradient.default,
  hoverBackground: gradient.dark,
};

export const GOLD_GRADIENT = {
  background: BADI_COLORS.GRADIENT.GRADIENT_02,
  color: BADI_COLORS.NEUTRAL.NEUTRAL_80,
};

export const INFO_BUTTON_TYPE = {
  color: colors.white,
  background: colors.blueBooking,
  hoverBackground: colors.blueBookingDark,
};
