import styled from "styled-components";
import { BADI_COLORS, mediaquery, unitizedPx } from "@badi/badi-components";
import { getPublicAsset } from "base/shared/Utils";
export const TestimonialContainer = styled.div`
  width: 100%;
  background-color: #6986ff; /* Blue color from image */
  overflow: hidden;
  margin-top: 0;
  padding: 40px ${unitizedPx(3)} 20px ${unitizedPx(3)};
  ${mediaquery.md`
    padding: 80px ${unitizedPx(8)}; 
  `}
  ${mediaquery.lg`
    padding: 80px ${unitizedPx(16)}; 
  `}
`;
export const TestimonialContainerWrapper = styled.div`
  max-width: ${unitizedPx(140)};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  ${mediaquery.md`
    flex-direction: row;
    align-items: center;
    gap: ${unitizedPx(8)};
   `}
`;
export const TestimonialContent = styled.div`
  flex: 1;

  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  position: relative;

  ${mediaquery.md`
  padding: 0 ${unitizedPx(5)};
  `}
`;

export const QuoteIcon = styled.div`
  height: 70px;
  width: 100px;
  background-position: top left;
  background-repeat: no-repeat;
  background-image: url(${getPublicAsset(
    "/images/landings/impulsa/quoteicon.svg",
  )});
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
`;

export const QuoteText = styled.p`
  font-size: 20px;
  line-height: 29px;
  font-weight: 500;
  margin-bottom: ${unitizedPx(4)};
  position: relative;
  z-index: 1;
  text-align: center;
  ${mediaquery.md`
    text-align: left;
    font-size: 25px;
    line-height: 28px; 
  `}
`;

export const AuthorName = styled.p`
  font-size: 16px;

  margin-bottom: ${unitizedPx(0.5)};
`;

export const AuthorTitle = styled.p`
  font-size: 16px;
`;

export const ImageContainer = styled.div`
  flex: 0 0 40%;
  position: relative;
  display: none;
  ${mediaquery.md`
    flex: 0 0 25%;
    display: block;
   `}
`;

export const PropertyImage = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 100%;
  border-radius: 14px;
  overflow: hidden;
  aspect-ratio: 379/438;
`;

export const PropertyTag = styled.div`
  position: absolute;
  width: 168px;
  bottom: ${unitizedPx(3)};
  left: -45px;
  text-align: left;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_90};
  padding: ${unitizedPx(1.5)} ${unitizedPx(2)};
  border-radius: ${unitizedPx(1)};
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
