import React from "react";
import PropTypes from "prop-types";
import { BADI_COLORS, mediaquery } from "@badi/badi-components";
import styled from "styled-components";

const StyledLogo = styled.div`
  width: 88px;
  height: 49px;
  ${mediaquery.lg`
    width: 131px;
    height: 69px;
  `}
`;

const ImpulsaLogo = ({
  primaryColor = BADI_COLORS.NEUTRAL.NEUTRAL_90,
  secondaryColor = BADI_COLORS.BRAND.SECONDARY.DEFAULT,
  className = "",
}) => (
  <StyledLogo>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 357.65 187.75"
    >
      <defs>
        <style>
          {`.cls-1 {
        fill: ${primaryColor};
      }
      .cls-2 {
        fill: ${secondaryColor};
      }`}
        </style>
      </defs>
      <g>
        <path
          class="cls-1"
          d="M14.33.49v31.86c6.34-9.41,20.26-10.55,29.67-5.28,15.92,8.92,17.67,34.93,4.08,46.7-7.75,6.71-20.55,8.2-29.16,2.27-2.15-1.48-3.5-3.33-5.19-5.26l-.4,7.96H.99V.49h13.34ZM27.63,35.39c-17.33,1.64-17.22,31.76.54,32.99,21.08,1.46,21.13-35.04-.54-32.99Z"
        />
        <path
          class="cls-1"
          d="M180.98.49v78.25h-12.94l-.4-8.36c-.46-.11-.33.09-.45.25-5.46,7.04-11.52,10.1-20.72,8.88-28.75-3.79-28.76-49.46-1.68-54.99,9.26-1.89,16.7.61,22.4,7.98.13.17,0,.36.46.25V.49h13.34ZM151.27,35.39c-17.25,1.76-17.07,31.89.73,33,21.22,1.33,20.71-35.19-.73-33Z"
        />
        <path
          class="cls-1"
          d="M106.41,78.74l-.3-.5-.4-7.86c-.91.85-1.54,1.98-2.38,2.89-6.34,6.84-16.76,8-25.2,4.68-26.3-10.36-18.57-58.03,13.32-53.74,6.16.83,10.91,3.97,14.26,9.13l.4-7.86.3-.5h12.64v53.76h-12.64ZM88.95,35.39c-10.61,1-14.38,12.48-12.21,21.64,3.6,15.19,24.33,15.14,27.92.04,2.61-11-3.06-22.88-15.71-21.68Z"
        />
        <rect class="cls-1" x="189.54" y="24.98" width="13.34" height="53.76" />
        <path
          class="cls-1"
          d="M194.86.14c12.18-1.89,12.62,16.56.95,15.68-9.3-.7-9.44-14.36-.95-15.68Z"
        />
      </g>
      <g>
        <path
          class="cls-2"
          d="M0,96.29c0-2.75,2.07-4.82,4.82-4.82s4.72,2.06,4.72,4.82-2.07,4.72-4.72,4.72-4.82-2.06-4.82-4.72ZM1.28,114.09h7.08v52.02H1.28v-52.02Z"
        />
        <path
          class="cls-2"
          d="M103.16,133.37v32.75h-7.08v-31.37c0-9.34-5.9-15.44-14.75-15.44s-14.75,6.1-14.75,15.44v31.37h-7.08v-31.37c0-9.34-5.9-15.44-14.65-15.44s-14.75,6.1-14.75,15.44v31.37h-7.08v-52.02h6.88l.1,8.26c3.15-5.8,8.95-9.24,17.01-9.24s14.56,4.03,17.6,10.62c3.15-6.49,9.34-10.62,18.1-10.62,12.29,0,20.46,8.06,20.46,20.26Z"
        />
        <path
          class="cls-2"
          d="M169.73,139.96c0,16.32-10.33,27.14-24.88,27.14-10.33,0-17.21-4.82-20.75-12.19v32.85h-7.08v-73.66h6.88l.2,11.21c3.54-7.38,10.42-12.19,20.75-12.19,14.55,0,24.88,10.72,24.88,26.85ZM162.35,140.05c0-12.49-7.67-20.75-19.18-20.75s-19.08,8.26-19.08,20.85,7.67,20.85,19.08,20.85,19.18-8.36,19.18-20.95Z"
        />
        <path
          class="cls-2"
          d="M226.07,114.09v52.02h-6.79l-.2-8.65c-3.34,6.1-9.44,9.64-18.19,9.64-12.39,0-20.65-8.16-20.65-20.16v-32.85h7.08v31.47c0,9.24,6.29,15.44,15.83,15.44s15.83-6.2,15.83-15.44v-31.47h7.08Z"
        />
        <path class="cls-2" d="M240.82,89.41h7.08v76.71h-7.08v-76.71Z" />
        <path
          class="cls-2"
          d="M259.8,161.59l2.95-4.43c4.33,3.15,9.64,4.23,14.95,4.23,7.38,0,12.29-3.44,12.29-8.75,0-4.92-4.62-7.67-10.92-9.44l-5.61-1.67c-8.06-2.26-13.77-5.51-13.77-13.28,0-9.15,7.38-15.15,18.29-15.15,6.98,0,12.69,1.48,17.7,4.72l-3.05,4.82c-4.42-2.75-9.15-3.83-14.16-3.83-6.79,0-11.51,3.25-11.51,8.36,0,4.82,4.13,6.98,9.74,8.56l5.61,1.67c7.87,2.16,14.95,6.1,14.95,14.65,0,9.05-7.57,15.05-19.57,15.05-7.38,0-13.87-1.87-17.9-5.51Z"
        />
        <path
          class="cls-2"
          d="M357.65,114.09v52.02h-6.79l-.29-11.21c-3.54,7.38-10.33,12.19-20.75,12.19-14.55,0-24.88-10.72-24.88-26.85s10.33-27.14,24.88-27.14c10.42,0,17.21,4.82,20.75,12.19l.29-11.21h6.79ZM350.56,140.15c0-12.49-7.67-20.85-19.08-20.85s-19.18,8.36-19.18,20.95,7.67,20.75,19.18,20.75,19.08-8.36,19.08-20.85Z"
        />
      </g>
    </svg>
  </StyledLogo>
);

ImpulsaLogo.propTypes = {
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  className: PropTypes.string,
};

export default ImpulsaLogo;
