import styled from "styled-components";
import { BADI_COLORS, mediaquery, unitizedPx } from "@badi/badi-components";
import { getPublicAsset } from "base/shared/Utils";

export const Container = styled.section`
  padding: ${unitizedPx(6)} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};

  margin: 0 auto;
  padding: ${unitizedPx(8)} 0;
  ${mediaquery.md`
    padding: 0;
    padding-bottom: 189px;
  `}
`;

export const Title = styled.h2`
  font-size: 36px;
  font-weight: 700;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_90};
  margin-bottom: ${unitizedPx(4)};
  text-align: left;
  width: 100%;
  max-width: ${unitizedPx(140)};
  padding: 0 ${unitizedPx(2)};
  ${mediaquery.md`
     margin-bottom: ${unitizedPx(2)};
  `}
`;

export const StepsContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 64px ${unitizedPx(2)};
  max-width: ${unitizedPx(140)};
`;

export const TimelineLine = styled.div`
  position: absolute;
  top: 120px;
  bottom: 140px;
  left: 50%;
  width: 2px;
  transform: translateX(-50%);
  z-index: 0;
  background-image: url(${getPublicAsset("/images/landings/impulsa/line.svg")});
  display: none;
  ${mediaquery.md`
    top: 180px;
    bottom: 160px;
    display: block;
  `}
`;

export const StepItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) =>
    props.isLastStep ? unitizedPx(2) : unitizedPx(6)};
  position: relative;
  z-index: 1;
  align-items: center;

  ${mediaquery.md`
    display: grid;
    grid-template-columns: 1fr 90px 1fr;
    text-align: center;
    margin-bottom: ${unitizedPx(8)};
  `}
  &:last-child {
    margin-bottom: 0;
  }
`;

export const StepNumberContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  z-index: 2;
  order: 1;
  width: 100%;
  ${mediaquery.md`
    order:unset;
    margin: ${unitizedPx(2)} 0;
    justify-content: center;
    align-items: center;
  `}
`;

export const StepNumber = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: ${BADI_COLORS.BRAND.SECONDARY.DEFAULT};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  position: relative;
`;

export const StepContent = styled.div`
  padding: ${unitizedPx(2)};
  text-align: center;
  order: 3;
  max-width: 340px;
  ${mediaquery.md`
 order:unset;
    margin-bottom: ${unitizedPx(2)};
  text-align: ${(props) => (props.position === "left" ? "right" : "left")};
    justify-self: ${(props) => (props.position === "left" ? "end" : "start")};;
  `}
  h3 {
    text-align: center;
    ${mediaquery.md`
    text-align: ${(props) => (props.position === "left" ? "right" : "left")};
   `}
  }
`;

export const StepTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_90};
  ${mediaquery.md`
    font-size: 25px;
    margin-bottom: 22px;
  `}
`;

export const StepDescription = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_60};
  margin: 0;
`;

export const StepImageContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  order: 2;
  margin-bottom: 0;
  ${mediaquery.md`
     justify-content: ${(props) => (props.position === "left" ? "flex-start" : "flex-end")};
    order:unset;
   
    justify-content: center;
  `}
`;

export const StepImage = styled.div`
  width: 100%;
  height: 122px;
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  ${mediaquery.md`
      width: 100%;
      height: 200px;
  `}
`;

export const SearchButton = styled.button`
  margin-top: ${unitizedPx(4)};
  padding: ${unitizedPx(1.5)} ${unitizedPx(3)};
  background-color: ${BADI_COLORS.BRAND.PRIMARY.DEFAULT};
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  border: none;
  border-radius: ${unitizedPx(0.5)};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: ${BADI_COLORS.BRAND.PRIMARY.HOVER};
  }
`;
