import React from "react";
import { getPublicAsset } from "base/shared/Utils";
import { translate } from "base/shared/Localization";
import {
  TestimonialContainer,
  TestimonialContent,
  QuoteIcon,
  QuoteText,
  AuthorName,
  AuthorTitle,
  TestimonialContainerWrapper,
  ImageContainer,
  PropertyImage,
  PropertyTag,
} from "./styled-components";

const Testimonial = () => {
  return (
    <TestimonialContainer>
      <TestimonialContainerWrapper>
        <TestimonialContent>
          <QuoteIcon></QuoteIcon>
          <QuoteText>"{translate("impulsa.testimonial.quote")}"</QuoteText>
          <AuthorName>{translate("impulsa.testimonial.author")}</AuthorName>
        </TestimonialContent>

        <ImageContainer>
          <PropertyImage
            src={getPublicAsset("/images/landings/impulsa/testimonial.jpg")}
            alt={translate("impulsa.testimonial.image_alt")}
          />
          <PropertyTag>
            {translate("impulsa.testimonial.image_alt")}
          </PropertyTag>
        </ImageContainer>
      </TestimonialContainerWrapper>
    </TestimonialContainer>
  );
};

export default Testimonial;
