import React, { Component } from "react";
import classNames from "classnames";

import { translate } from "base/shared/Localization";
import { KEYCODE } from "base/shared/Utils";

import BookVirtualTourButton from "./BookVirtualTourButton";
import Button from "components/Button/Button";
import ProgressiveImg from "components/ProgressiveImg";
import Thumb from "./wrappers/Thumb";
import PhotoGrid from "./PhotoGrid";

import "./stylesExt.css";

const getPictures = (pictures) =>
  pictures.map((p) => (
    <link href={p.width_500_url} key={p.id} rel="prefetch" />
  ));

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      prevIndex: props.pictures.length - 1,
      nextIndex: 1,
      isOpen: false,
    };

    this.closeGallery = this.closeGallery.bind(this);
    this.getIndex = this.getIndex.bind(this);
    this.getContent = this.getContent.bind(this);
    this.getPanel = this.getPanel.bind(this);
    this.getSlider = this.getSlider.bind(this);
    this.keydownHandler = this.keydownHandler.bind(this);
    this.next = this.next.bind(this);
    this.openGallery = this.openGallery.bind(this);
    this.previous = this.previous.bind(this);
    this.selectPicture = this.selectPicture.bind(this);
  }

  getIndex(index) {
    const length = this.props.pictures.length - 1;

    if (index < 0) {
      return {
        activeIndex: length,
        prevIndex: length - 1,
        nextIndex: length <= 1 ? -1 : 1,
      };
    }
    if (index > length) {
      return { activeIndex: 0, prevIndex: length, nextIndex: 1 };
    }
    return {
      activeIndex: index,
      prevIndex: index <= 0 ? -1 : index - 1,
      nextIndex: index + 1,
    };
  }

  getSlider(pictures) {
    const { activeIndex, nextIndex, prevIndex } = this.state;

    return (
      <div className="gallery__slider" key="gallerySlider">
        <ul className="gallery__list">
          {pictures.map((p, i) => (
            <li
              className={classNames(
                "gallery__item",
                { gallery__item_prev: i === prevIndex },
                { gallery__item_next: i === nextIndex },
                { gallery__item_active: i === activeIndex },
              )}
              key={p.id}
            >
              <div className="gallery__box">
                <ProgressiveImg
                  className="gallery__photo"
                  imgClassName="gallery__photo_img"
                  src={p.width_1080_url}
                  tinySrc={p.width_27_url}
                />
              </div>
            </li>
          ))}
        </ul>
        <button className="gallery__prev" onClick={this.previous} />
        <button className="gallery__next" onClick={this.next} />
      </div>
    );
  }

  getPanel(pictures) {
    const { activeIndex } = this.state;

    return (
      <div className="gallery__panel" key="galleryPanel">
        <div className="gallery__counter">{`${activeIndex + 1}/${
          pictures.length
        }`}</div>
        <div className="gallery__previews">
          <ul className="gallery__sublist">
            {pictures.map((p, i) => (
              <Thumb
                key={p.id}
                onClick={this.selectPicture}
                picture={p}
                selected={activeIndex === i}
              />
            ))}
          </ul>
        </div>
      </div>
    );
  }

  getContent(pictures) {
    return [
      getPictures(pictures),
      this.getSlider(pictures),
      this.getPanel(pictures),
    ];
  }

  closeGallery(e) {
    e.preventDefault();

    document.onkeydown = null;
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("popup_open");

    this.gallery.classList.add("gallery_closed");

    setTimeout(() => {
      this.gallery.classList.remove("gallery_active");
      this.gallery.classList.remove("gallery_closed");
      this.setState({ isOpen: false });
    }, 300);
  }

  previous(e) {
    e.preventDefault();
    const { activeIndex } = this.state;

    const newIndex = this.getIndex(activeIndex - 1);
    this.setState({ ...newIndex });
  }

  openGallery(e) {
    e.preventDefault();

    document.onkeydown = this.keydownHandler;
    this.gallery.classList.add("gallery_active");
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("popup_open");
    const selectedId = e.currentTarget.id || 0;
    this.selectPicture(parseInt(selectedId, 10));
    this.setState({ isOpen: true });
  }

  selectPicture(id) {
    const index = id ? this.props.pictures.findIndex((p) => p.id === id) : 0;
    this.setState(this.getIndex(index));
  }

  next(e) {
    e.preventDefault();
    const { activeIndex } = this.state;

    const newIndex = this.getIndex(activeIndex + 1);
    this.setState({ ...newIndex });
  }

  keydownHandler(e) {
    const event = e || window.event;

    switch (event.keyCode) {
      case KEYCODE.left:
        this.previous(e);
        break;
      case KEYCODE.right:
        this.next(e);
        break;
      case KEYCODE.escape:
        this.closeGallery(e);
        break;
      default:
        break;
    }
  }

  render() {
    const { className, pictures, isBadiHome, virtualTourLink } = this.props;
    const { isOpen } = this.state;
    const content = isOpen ? this.getContent(pictures) : null;

    return (
      <div>
        <div className="room__slider">
          <div className={className}>
            <PhotoGrid handleClick={this.openGallery} pictures={pictures} />
            <div
              className="gallery"
              ref={(i) => {
                this.gallery = i;
              }}
            >
              <div
                className="gallery__bg"
                onClick={this.closeGallery}
                role="presentation"
              />
              {content}
              <div
                className="gallery__close"
                onClick={this.closeGallery}
                role="presentation"
              />
            </div>
          </div>
          <div className="room__all">
            <Button
              config={{ color: "white", size: "small" }}
              onClick={this.openGallery}
              text={translate("see_photos", { smart_count: pictures.length })}
            />
            {isBadiHome && (
              <BookVirtualTourButton virtualTourLink={virtualTourLink} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Gallery.defaultProps = {
  isBadiHome: false,
};

export default Gallery;
