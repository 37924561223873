import React, { useState, useEffect } from "react";
import { Spacer } from "@badi/badi-components";
import InjectHtml from "components/inject-html";
import { translate } from "base/shared/Localization";
import {
  FAQContainer,
  FAQTitle,
  AccordionButton,
  AccordionContent,
  AccordionItem,
  AccordionIcon,
  BenefitDescription,
  FAQGrid,
  FAQQuestions,
  FAQAnswers,
} from "./styled-components";

const FAQ = () => {
  const [openItem, setOpenItem] = useState(null);
  const [isDesktop, setIsDesktop] = useState(false);

  const getFAQItems = () => [
    {
      id: "what-is",
      title: "impulsa.faq.what_is.title",
      content: [
        {
          description: "impulsa.faq.what_is.description",
        },
      ],
    },
    {
      id: "why-important",
      title: "impulsa.faq.why_important.title",
      content: [
        {
          description: "impulsa.faq.why_important.description1",
        },
        {
          description: "impulsa.faq.why_important.description2",
        },
        {
          description: "impulsa.faq.why_important.description3",
        },
        {
          description: "impulsa.faq.why_important.description4",
        },
      ],
    },
    {
      id: "how-works",
      title: "impulsa.faq.how_works.title",
      content: [
        {
          description: "impulsa.faq.how_works.description1",
        },
        {
          description: "impulsa.faq.how_works.description2",
        },
        {
          description: "impulsa.faq.how_works.description3",
        },
      ],
    },
  ];

  useEffect(() => {
    const checkDesktop = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    checkDesktop();
    window.addEventListener("resize", checkDesktop);

    return () => window.removeEventListener("resize", checkDesktop);
  }, []);

  useEffect(() => {
    const faqItems = getFAQItems();
    if (isDesktop && !openItem && faqItems.length > 0) {
      setOpenItem(faqItems[0].id);
    }
  }, [isDesktop]);

  const handleClick = (itemId) => {
    setOpenItem(openItem === itemId ? null : itemId);
  };

  const renderQuestions = () => {
    const faqItems = getFAQItems();
    return (
      <FAQQuestions>
        {faqItems.map((item) => (
          <AccordionItem key={item.id}>
            <AccordionButton
              isOpen={openItem === item.id}
              onClick={() => handleClick(item.id)}
              type="button"
              aria-expanded={openItem === item.id}
              aria-controls={`content-${item.id}`}
            >
              <InjectHtml>{translate(item.title)}</InjectHtml>
              <AccordionIcon isOpen={openItem === item.id}></AccordionIcon>
            </AccordionButton>
          </AccordionItem>
        ))}
      </FAQQuestions>
    );
  };

  const renderAnswers = () => {
    const faqItems = getFAQItems();
    return (
      <FAQAnswers>
        {isDesktop
          ? openItem && (
              <AccordionContent id={`content-${openItem}`} isVisible={true}>
                {faqItems
                  .find((item) => item.id === openItem)
                  ?.content.map((content, index) => (
                    <div key={index}>
                      <BenefitDescription>
                        <InjectHtml>
                          {translate(content.description)}
                        </InjectHtml>
                      </BenefitDescription>
                    </div>
                  ))}
              </AccordionContent>
            )
          : faqItems.map((item) => (
              <AccordionContent
                key={item.id}
                id={`content-${item.id}`}
                isVisible={openItem === item.id}
              >
                {item.content.map((content, index) => (
                  <div key={index}>
                    <BenefitDescription>
                      <InjectHtml>{translate(content.description)}</InjectHtml>
                    </BenefitDescription>
                  </div>
                ))}
              </AccordionContent>
            ))}
      </FAQAnswers>
    );
  };

  return (
    <FAQContainer>
      <Spacer top={5}>
        <FAQTitle>
          <InjectHtml>{translate("impulsa.faq.title")}</InjectHtml>
        </FAQTitle>
        <Spacer top={3}>
          {isDesktop ? (
            <FAQGrid>
              {renderQuestions()}
              {renderAnswers()}
            </FAQGrid>
          ) : (
            <>
              {getFAQItems().map((item) => (
                <AccordionItem key={item.id}>
                  <AccordionButton
                    isOpen={openItem === item.id}
                    onClick={() => handleClick(item.id)}
                    type="button"
                    aria-expanded={openItem === item.id}
                    aria-controls={`content-${item.id}`}
                  >
                    <InjectHtml>{translate(item.title)}</InjectHtml>
                    <AccordionIcon
                      isOpen={openItem === item.id}
                    ></AccordionIcon>
                  </AccordionButton>
                  {openItem === item.id && (
                    <AccordionContent id={`content-${item.id}`}>
                      {item.content.map((content, index) => (
                        <div key={index}>
                          <BenefitDescription>
                            <InjectHtml>
                              {translate(content.description)}
                            </InjectHtml>
                          </BenefitDescription>
                        </div>
                      ))}
                    </AccordionContent>
                  )}
                </AccordionItem>
              ))}
            </>
          )}
        </Spacer>
      </Spacer>
    </FAQContainer>
  );
};

export default FAQ;
