import React from "react";

import { BADI_COLORS, Text, Spacer, Flex } from "@badi/badi-components";
import { Button, BUTTON_TYPES } from "app/badi-components";

import { StyledMessage, MessageMeta, TextWrapper } from "./styled-components";

const ChatMessage = ({
  avatar,
  date,
  message = "",
  name,
  isCurrentUser = false,
  isFastTrack = false,
  externalFromCta = null,
}) => (
  <Flex alignItems="flexEnd" gutter={2} reverse={isCurrentUser}>
    {avatar}
    <StyledMessage
      grow={1}
      isCurrentUser={isCurrentUser}
      isFastTrack={isFastTrack}
    >
      <Spacer bottom={2} left={2} right={2} top={2}>
        <TextWrapper>
          {externalFromCta ? (
            <Text
              dataQa="chat-message"
              dangerouslySetInnerHTML={{
                __html: message.replace(/\n/g, "<br/>"),
              }}
            ></Text>
          ) : (
            <Text dataQa="chat-message">{message}</Text>
          )}
        </TextWrapper>
        <Spacer top={2}>
          <MessageMeta>
            <Text body={3} color={BADI_COLORS.NEUTRAL.NEUTRAL_50}>
              {`${name} · ${date}`}
            </Text>
          </MessageMeta>
        </Spacer>
      </Spacer>
      {externalFromCta && (
        <Spacer top={2}>
          <Button
            basis="100%"
            data-qa="form-button"
            fullWidth={true}
            onClick={() => {
              window.open(externalFromCta.url, "_blank");
            }}
            variant={BUTTON_TYPES.INFO}
          >
            {externalFromCta.text}
          </Button>
        </Spacer>
      )}
    </StyledMessage>
  </Flex>
);

export default ChatMessage;
