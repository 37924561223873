import React, { useState, useEffect, memo } from "react";
import { Flex, BREAKPOINTS, useMatchMedia } from "@badi/badi-components";
import { FILTERS, FILTERS_ID } from "Sections/Search/search-v2/filters/static";
import {
  isMissingLocation,
  geocodeByAddressOrPlaceId,
} from "app/components/Layout/TopBar/components/search-bar/utils";
import { parse } from "qs";

import { SEARCH_VIEW } from "datamodel/SearchFilters/static";
import HeaderLang from "components/header-lang";
import { HEADER_PARAMS } from "base/shared/tracking-headers/static";
const { SEARCH_SOURCES } = HEADER_PARAMS;

import LayoutWrapper from "components/Layout/Wrapper";
import Filters from "./filters";
import {
  SearchFiltersWrapper,
  SearchWrapper,
} from "./wrappers/styled-components";

import SearchResults from "./search-results";

const MEDIA_QUERIES = [`(min-width: ${BREAKPOINTS.lg}px)`];

const Search = ({
  handleRequireLogin,
  isLoginRequired,
  location = null,
  params,
  searchRooms,
  searchfilters,
  searchfiltersPreviousToken = null,
  setBoundsRecalculation,
  setLocation,
  setToken,
  resetLoginRequired,
}) => {
  const [view, setView] = useState(SEARCH_VIEW.list);
  const [listAndMapVisible] = useMatchMedia(MEDIA_QUERIES);

  const handleSuggestUpdate = (suggest) => {
    const { label, meta } = suggest;
    const city = meta ? meta.city : "";
    setBoundsRecalculation();
    setLocation({ ...suggest, city, text: label });
  };

  useEffect(() => {
    if (isMissingLocation(params.bounds, params.coordinates, params.text)) {
      geocodeByAddressOrPlaceId({
        address: params.text,
        placeId: params.placeId,
        setLocation: handleSuggestUpdate,
      });
    }
  }, []);

  useEffect(() => {
    if (searchfilters.bounds) {
      searchRooms({
        filters: searchfilters,
        searchSource: SEARCH_SOURCES.options.SEARCH_BAR,
      });
    }
  }, [JSON.stringify(searchfilters)]);

  useEffect(() => {
    if (isLoginRequired) {
      handleRequireLogin();
      resetLoginRequired();
      setToken(searchfiltersPreviousToken);
    }
  }, [isLoginRequired]);

  const handleOnSelectView = (selectedView) => {
    setView(selectedView);
  };

  const canonicalPath = location?.pathname;

  return (
    <LayoutWrapper>
      <SearchWrapper>
        <Flex as={SearchFiltersWrapper} direction="row">
          <Filters
            onSelectView={handleOnSelectView}
            view={view}
            filters={FILTERS}
          />
        </Flex>
        <SearchResults
          onSelectView={handleOnSelectView}
          view={listAndMapVisible ? SEARCH_VIEW.both : view}
        />
      </SearchWrapper>
      <HeaderLang canonicalUrl={canonicalPath} relativePath={canonicalPath} />
    </LayoutWrapper>
  );
};

export default memo(Search);
