import React, { memo, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Spacer, Anchor, Flex, Text, Heading } from "@badi/badi-components";
import { useInView } from "react-intersection-observer";

import { getGender } from "base/shared/utils/room";
import { getOccupation } from "base/shared/User";
import { locale, translate } from "base/shared/Localization";
import SimilarRooms from "components/similar-rooms";
import StaticMap from "components/static-map";
import RoomMates from "components/RoomMates";
import BlockSeparator from "components/block-separator";

import { fetchDescriptionTranslation } from "datamodel/Room/api";
import LabelModel from "datamodel/Room/models/label-model";
import UserFeedback from "base/shared/user-feedback";
import ReviewsSection from "./components/reviews-section";
import DynamicSectionTypeText from "./components/dynamic-section-type-text";
import DynamicSectionTypeIcon from "./components/dynamic-section-type-icon";

import Verified from "./components/verified";
import MatchProfile from "./components/match-profile";
import BadiHomesBadge from "./components/badi-homes-badge";
import {
  SUPPORTED_TRANSLATION_LANGUAGES,
  SECTION_TYPE_TEXT_PROPS,
  SECTION_TYPE_ICON_PROPS,
} from "./constants";

import TranslateButton from "./components/translate-button";

const UNIX_AND_WINDOWS_LINEBREAK = new RegExp("\r\n|\n\n");

const RoomDetailContent = ({
  description = "",
  displayAddress = "",
  femaleTenants = 0,
  nonBinaryTenants = 0,
  latitude = null,
  longitude = null,
  maleTenants = 0,
  matchProfile = false,
  owned = false,
  preferences = {},
  tenants,
  id = 0,
  token,
  similarRoomsList,
  userId,
  verified,
  virtualTourLink = null,
  entireProperty,
  etParams = [],
  reviews = [],
  reviewsTotal = 0,
  labels = [],
  descriptionLanguage = null,
  servicesIncluded = null,
  flatAmenities = null,
  abstract = null,
  propertyDetails = null,
  roomAmenities = null,
  priceDetails = null,
}) => {
  const genders = getGender(preferences.genders);
  const occupation = preferences
    ? getOccupation(preferences.occupation, "name")
    : null;

  const [mapRef, mapInView] = useInView({
    rootMargin: "100px 0px 0px 0px",
    triggerOnce: true,
  });

  const isTranslateButtonVisible =
    SUPPORTED_TRANSLATION_LANGUAGES.includes(descriptionLanguage) &&
    descriptionLanguage !== locale();
  const [descriptionTranslation, setDescriptionTranslation] = useState(null);
  const [loadingDescriptionTranslation, setLoadingDescriptionTranslation] =
    useState(false);

  const [showTranslation, setShowTranslation] = useState(false);

  const onTranslateButtonClick = () => {
    setLoadingDescriptionTranslation(true);
    if (!descriptionTranslation)
      fetchDescriptionTranslation(token, {
        roomId: id,
        targetLanguage: locale(),
      })
        .then(({ data: { description: translation } }) => {
          setDescriptionTranslation(translation);
          setLoadingDescriptionTranslation(false);
          setShowTranslation(true);
          setLoadingDescriptionTranslation(false);
        })
        .catch(() => {
          UserFeedback.exception(
            translate("Something went wrong, try again or contact support."),
          );
          setLoadingDescriptionTranslation(false);
        });
    else {
      setShowTranslation(!showTranslation);
      setLoadingDescriptionTranslation(false);
    }
  };

  const badiHomeLabel = labels.find(
    (label) => label.id == LabelModel.IDS.badi_homes,
  );

  return (
    <Spacer md={{ left: 2, right: 4 }} xs={{ left: 1, right: 1 }}>
      {abstract && (
        <Spacer bottom={4}>
          <DynamicSectionTypeIcon
            dataQa="section-abstract"
            {...abstract}
            type="abstract"
          />
        </Spacer>
      )}
      {verified && (
        <Spacer bottom={4} top={4}>
          <Verified />
        </Spacer>
      )}
      {badiHomeLabel && (
        <Spacer bottom={4} top={4}>
          <BadiHomesBadge
            color={badiHomeLabel.color}
            backgroundColor={badiHomeLabel.backgroundColor}
            title={badiHomeLabel.content}
            description={badiHomeLabel.description}
          />
        </Spacer>
      )}
      {!owned && matchProfile && (
        <Spacer bottom={4} top={4}>
          <MatchProfile />
        </Spacer>
      )}
      {description && (
        <BlockSeparator space={4}>
          <Heading data-qa="room-detail-description-title" level="20-Bold">
            {translate("Description")}
          </Heading>
          <Spacer bottom={2}>
            {isTranslateButtonVisible && (
              <TranslateButton
                dataQa="translate-button"
                loadingDescriptionTranslation={loadingDescriptionTranslation}
                onClick={onTranslateButtonClick}
                showTranslation={showTranslation}
              />
            )}
          </Spacer>
          {(showTranslation ? descriptionTranslation : description)
            .split(UNIX_AND_WINDOWS_LINEBREAK)
            .map((paragraph, idx) => (
              <p
                style={{ textAlign: "justify" }}
                data-qa="room-detail-description"
                key={idx}
              >
                {paragraph}
              </p>
            ))}
        </BlockSeparator>
      )}
      {propertyDetails && (
        <BlockSeparator space={4}>
          <DynamicSectionTypeText
            dataQa="section-property-details"
            {...propertyDetails}
          />
        </BlockSeparator>
      )}
      {!entireProperty && (
        // !TODO TO BE REFACTORED WITH DYNAMIC SECTION
        <BlockSeparator space={4}>
          <RoomMates
            femaleTenants={femaleTenants}
            maleTenants={maleTenants}
            nonBinaryTenants={nonBinaryTenants}
            tenants={tenants}
            title="Flatmates"
            token={token}
          />
        </BlockSeparator>
      )}
      {!entireProperty && preferences && (
        // !TODO TO BE REFACTORED WITH DYNAMIC SECTION
        <BlockSeparator space={4}>
          <Container>
            <Row>
              <Col xs={12}>
                <Spacer bottom={4}>
                  <Heading data-qa="flatmate-preference-title" level="20-Bold">
                    {translate("room.detail.flatmates.title")}
                  </Heading>
                </Spacer>
              </Col>
            </Row>
            <Row>
              {genders && (
                <Col md={4} xs={12}>
                  <Text body="14-Regular" dataQa="flatmate-preference-genders">
                    {translate(genders)}
                  </Text>
                </Col>
              )}
              {preferences.max_age && preferences.min_age && (
                <Col md={4} xs={12}>
                  <Text body="14-Regular" dataQa="flatmate-preference-age">
                    {translate("room.detail.fields.age", {
                      min_age: preferences.min_age,
                      max_age:
                        preferences.max_age === 40
                          ? "40+"
                          : preferences.max_age,
                    })}
                  </Text>
                </Col>
              )}
              {occupation && (
                <Col md={4} xs={12}>
                  {translate(occupation.displayTitle)}
                </Col>
              )}
            </Row>
          </Container>
        </BlockSeparator>
      )}
      {flatAmenities && (
        <BlockSeparator space={4}>
          <DynamicSectionTypeIcon
            dataQa="section-flat-amenities"
            {...flatAmenities}
          />
        </BlockSeparator>
      )}
      {roomAmenities && (
        <BlockSeparator space={4}>
          <DynamicSectionTypeIcon
            dataQa="section-room-amenities"
            {...roomAmenities}
          />
        </BlockSeparator>
      )}
      {reviews.length > 0 && (
        <BlockSeparator space={4}>
          <ReviewsSection reviews={reviews} roomId={id} total={reviewsTotal} />
        </BlockSeparator>
      )}
      {priceDetails && (
        <BlockSeparator space={4}>
          <DynamicSectionTypeText
            dataQa="section-price-details"
            {...priceDetails}
          />
        </BlockSeparator>
      )}
      <BlockSeparator space={4}>
        <div ref={mapRef}>
          <Heading data-qa="room-details-location-title" level="20-Bold">
            {translate("Location")}
          </Heading>
          <Spacer bottom={1} top={1}>
            <Text body="14-Regular" dataQa="room-details-location-address">
              {displayAddress}
            </Text>
          </Spacer>
          {mapInView && (
            <StaticMap
              dataQa="room-details-static-map"
              latitude={latitude}
              longitude={longitude}
              marker={{ latitude, longitude }}
              token={token}
            />
          )}
        </div>
      </BlockSeparator>
      {servicesIncluded && (
        <BlockSeparator space={4}>
          <DynamicSectionTypeText
            dataQa="section-services-included"
            {...servicesIncluded}
          />
        </BlockSeparator>
      )}
      {!!similarRoomsList.length && (
        <SimilarRooms
          dataQa="room-details-similar-rooms"
          etParams={etParams}
          parentRoomId={id}
          similarRoomsList={similarRoomsList}
          userId={userId}
        />
      )}
    </Spacer>
  );
};

export default memo(RoomDetailContent);
