import React, { useRef } from "react";
import Helmet from "react-helmet";
import { locale, translate } from "base/shared/Localization";
import { getPublicAsset, getRelativePath } from "base/shared/Utils";
import { Spacer, Button } from "@badi/badi-components";
import {
  AUTHENTICATION_FORM_MODE,
  openAuthenticationModal,
} from "app/Sections/auth/forms/authentication-form";
import { modal, MODAL_ID } from "containers/ReduxModal/emitter";
import styled from "styled-components";
import InjectHtml from "components/inject-html";
import { Link } from "react-router";
import { getSearchUrl } from "datamodel/SearchFilters/utils";
import { HEADER_PARAMS } from "base/shared/tracking-headers/static";

import {
  HeaderContainer,
  LoginButton,
  MainSection,
  MainHeading,
  Description,
  SearchButton,
  CardsContainer,
  MainHeadingWrapper,
  ButtonWrapper,
  Card,
  CardImage,
  CardContent,
  CardTitle,
  CardDescription,
  MainHeadingContainer,
} from "./styled-components";

import ImpulsaLogo from "./components/impulsa-logo";
import FeaturedImpulsa from "./components/featured-impulsa/featured-impulsa";
import FAQ from "./components/faq/faq";
import HowItWorks from "./components/how-it-works";
import Testimonial from "./components/testimonial";
import Footer from "./components/footer";

const Avatar = styled.a`
  display: block;
  width: 42px;
  height: 42px;
  border: 2px solid #fff;
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;
  background-image: url(${({ picture }) => picture});
  cursor: pointer;
`;

const CardWrapper = styled.div`
  cursor: pointer;
`;

const ImpulsaLanding = ({ token, picture, isLoggedIn }) => {
  const description = translate("impulsa.meta.description");
  const title = translate("impulsa.meta.title");

  // Create a ref for the featured section
  const featuredSectionRef = useRef(null);

  // Scroll to the main featured section
  const scrollToFeatured = () => {
    if (featuredSectionRef.current) {
      featuredSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Scroll to a specific category carousel
  const scrollToCategory = (categoryId) => {
    const categorySection = document.querySelector(
      `[data-category-id="${categoryId}"]`,
    );
    if (categorySection) {
      categorySection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleLogin = () => {
    const handleClose = () => {
      modal.remove(MODAL_ID.AUTH);
    };

    openAuthenticationModal({
      mode: AUTHENTICATION_FORM_MODE.LOGIN,
      onSuccess: () => {
        window.location.reload();
      },
      onClose: handleClose,
    });
  };

  return (
    <>
      <HeaderContainer>
        <ImpulsaLogo />
        {isLoggedIn ? (
          <Avatar
            data-qa="impulsa-topbar-user-avatar"
            picture={picture}
            href={getRelativePath("/my-profile")}
          />
        ) : (
          <LoginButton
            data-qa="impulsa-topbar-public-menu-login"
            onClick={handleLogin}
          >
            {translate("impulsa.header.login")}
          </LoginButton>
        )}
      </HeaderContainer>

      <MainSection>
        <MainHeadingWrapper>
          <MainHeadingContainer>
            <MainHeading>
              <InjectHtml>{translate("impulsa.main.title")}</InjectHtml>
            </MainHeading>
            <Description>
              <InjectHtml>{translate("impulsa.main.description")}</InjectHtml>
            </Description>
            <ButtonWrapper>
              <Button
                onClick={scrollToFeatured}
                data-qa="impulsa-search-button"
              >
                {translate("impulsa.main.search_button")}
              </Button>
            </ButtonWrapper>
          </MainHeadingContainer>
        </MainHeadingWrapper>
        <CardsContainer>
          <CardWrapper onClick={() => scrollToCategory(3)}>
            <Card>
              <CardContent>
                <CardImage
                  src={getPublicAsset(
                    "/images/landings/impulsa/community-living.png",
                  )}
                />
                <CardTitle>
                  {translate("impulsa.cards.community_living.title")}
                </CardTitle>
              </CardContent>
              <CardDescription>
                {translate("impulsa.cards.community_living.description")}
              </CardDescription>
            </Card>
          </CardWrapper>

          <CardWrapper onClick={() => scrollToCategory(1)}>
            <Card>
              <CardContent>
                <CardImage
                  src={getPublicAsset(
                    "/images/landings/impulsa/residences.png",
                  )}
                />
                <CardTitle>
                  {translate("impulsa.cards.residences.title")}
                </CardTitle>
              </CardContent>
              <CardDescription>
                {translate("impulsa.cards.residences.description")}
              </CardDescription>
            </Card>
          </CardWrapper>

          <CardWrapper onClick={() => scrollToCategory(2)}>
            <Card>
              <CardContent>
                <CardImage
                  src={getPublicAsset("/images/landings/impulsa/training.png")}
                />
                <CardTitle>
                  {translate("impulsa.cards.training.title")}
                </CardTitle>
              </CardContent>
              <CardDescription>
                {translate("impulsa.cards.training.description")}
              </CardDescription>
            </Card>
          </CardWrapper>

          <CardWrapper onClick={() => scrollToCategory(4)}>
            <Card>
              <CardContent>
                <CardImage
                  src={getPublicAsset("/images/landings/impulsa/bed.jpg")}
                />
                <CardTitle>
                  {translate("impulsa.cards.community_support.title")}
                </CardTitle>
              </CardContent>
              <CardDescription>
                {translate("impulsa.cards.community_support.description")}
              </CardDescription>
            </Card>
          </CardWrapper>
        </CardsContainer>

        <div ref={featuredSectionRef}>
          <FeaturedImpulsa token={token} />
        </div>

        <FAQ />

        <HowItWorks onSearch={scrollToFeatured} />

        <Testimonial />
      </MainSection>

      <Footer />

      <Helmet
        meta={[
          { property: "og:title", content: title },
          { name: "description", content: description },
          { property: "og:description", content: description },
        ]}
        title={title}
      />
    </>
  );
};

export default ImpulsaLanding;
