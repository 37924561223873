import React, { useRef, useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import { ANNOTATION_ACTION_TYPE_BUTTON } from "datamodel/Conversation/constants";
import SystemMessage from "components/system-message";
import UserMessage from "../user-message";
import { MESSAGE_TYPES } from "../../constants";
import { MessageListRoot } from "./styled-components";
import { autoScroll } from "./utils";

const MESSAGES_PER_PAGE = 20;

const MessageList = ({
  currentPage = 0,
  getMessages,
  lastMessageId,
  markConnectionAsRead,
  messages = [],
  totalPages = 0,
  userId,
  statusLevel,
}) => {
  const [prevHeight, setPrevHeight] = useState(0);
  const messageListRef = useRef(null);

  const loadMoreMessages = (force = false) => {
    if (!force && totalPages <= currentPage) {
      return;
    }
    setPrevHeight(messageListRef?.current?.scrollHeight);

    getMessages({
      page: currentPage + 1,
      per: MESSAGES_PER_PAGE,
    });
  };

  const handleScrollTop = (top) => {
    if (top) {
      loadMoreMessages();
    }
  };

  useEffect(() => {
    loadMoreMessages(true);
    markConnectionAsRead();
  }, []);

  useEffect(() => {
    if (currentPage > 1) {
      autoScroll({
        element: messageListRef?.current,
        height: prevHeight,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    autoScroll({ element: messageListRef?.current });
  }, [lastMessageId]);

  return (
    <MessageListRoot data-qa="chat-messages" ref={messageListRef}>
      <InView onChange={handleScrollTop} />
      {/* TODO: This reverse is temporal until we update the datamodel */}
      {[...messages].reverse().map((message) => {
        const {
          id,
          content,
          date,
          type,
          user,
          annotations,
          url = null,
          externalFromCta,
        } = message;

        if (type === MESSAGE_TYPES.SYSTEM) {
          const actionType = message.primaryAction
            ? ANNOTATION_ACTION_TYPE_BUTTON
            : null;
          return (
            <SystemMessage actionType={actionType} content={content} key={id} />
          );
        }

        return (
          <UserMessage
            annotations={annotations}
            content={content}
            currentUserId={userId}
            date={date}
            id={id}
            key={id}
            type={type}
            url={url}
            user={user}
            statusLevel={statusLevel}
            externalFromCta={externalFromCta}
          />
        );
      })}
    </MessageListRoot>
  );
};

export default MessageList;
