import styled from "styled-components";
import { BADI_COLORS, mediaquery, unitizedPx } from "@badi/badi-components";

export const FAQContainer = styled.div`
  width: 100%;
  max-width: 100%;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  padding-left: ${unitizedPx(3)};
  padding-right: ${unitizedPx(3)};
  padding-top: ${unitizedPx(6)};
  padding-bottom: ${unitizedPx(6)};
  ${mediaquery.md`
    padding-left:${unitizedPx(6)};
    padding-right:${unitizedPx(6)};
    padding-top:${unitizedPx(8)};
    padding-bottom:${unitizedPx(8)};
  `}
`;

export const FAQTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
  margin-bottom: ${unitizedPx(2)};
  max-width: ${unitizedPx(140)};
  margin: 0 auto;

  ${mediaquery.md`
    font-size: 32px;
     margin-bottom:48px;

  `}
`;

export const FAQGrid = styled.div`
  display: block;
  max-width: ${unitizedPx(140)};
  margin: 0 auto;
  ${mediaquery.md`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${unitizedPx(4)};
  `}
`;

export const FAQQuestions = styled.div`
  ${mediaquery.md`
    position: sticky;
    top: ${unitizedPx(2)};
  `}
`;

export const FAQAnswers = styled.div`
  ${mediaquery.md`
    position: relative;
    min-height: 400px;
  `}
`;

export const AccordionItem = styled.div`
  margin-bottom: 10px;
`;

export const AccordionButton = styled.button`
  width: 100%;
  padding: 25px ${unitizedPx(4)};
  background-color: ${(props) => (props.isOpen ? "#5B82F9" : "#DDDCFD")};
  color: ${(props) => (props.isOpen ? "#FFFFFF" : "#6986FF")};
  opacity: ${(props) => (props.isOpen ? 1 : 0.5)};
  border: none;
  border-radius: ${unitizedPx(1)};
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  &:hover {
    background-color: ${(props) =>
      props.isOpen ? BADI_COLORS.BRAND.PRIMARY.DARK : "#EEEAFF"};
  }
`;

export const AccordionIcon = styled.span`
  font-size: 24px;
  font-weight: 100;
  transition: transform 0.3s ease;
  position: absolute;
  right: 16px;
  display: inline-block;
  &:after {
    transform: translateY(${(props) => (props.isOpen ? "-4px" : "0")});
    display: inline-block;
    font-family: "Inter", sans-serif;
    font-weight: ${(props) => (props.isOpen ? "600" : "300")};
    font-size: ${(props) => (props.isOpen ? "14px" : "24px")};
    content: "${(props) => (props.isOpen ? "—" : "+")}";
  }

  ${mediaquery.md`
    right: 20px;
  `}
`;

export const AccordionContent = styled.div`
  padding: ${unitizedPx(3)};
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  border-radius: 0 0 ${unitizedPx(1)} ${unitizedPx(1)};
  border: 1px solid #5b82f9;
  margin-top: -5px;
  ${mediaquery.md`
    border:none;
   padding: 0 ${unitizedPx(3)} ${unitizedPx(3)} ${unitizedPx(3)};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
    transition: opacity 0.3s ease, visibility 0.3s ease;
    margin-top: 0;
    border-radius: ${unitizedPx(1)};
    height: 100%;
    
  `}
`;

export const BenefitDescription = styled.p`
  font-size: 16px;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_50};
  line-height: 1.6;
  margin: 0;
  padding-bottom: ${unitizedPx(3)};

  b {
    font-weight: 600;
    color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
  }

  ${mediaquery.md`
    font-size: 17px;
  `}
`;
