import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { fetchImpulsaRoomsByCategory } from "../../api";
import { Heading, Spacer, Text } from "@badi/badi-components";
import RoomCard from "./room-card";
import Slider from "app/components/SliderV2";
import {
  LoadingState,
  TextWrapper,
  CategoryContainer,
} from "./styled-components";

const FeaturedCategory = ({ token, categoryId, title, description }) => {
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadRooms = async () => {
      try {
        setLoading(true);
        const { rooms: apiRooms } = await fetchImpulsaRoomsByCategory({
          token,
          categoryId,
        });
        setRooms(apiRooms || []);
      } catch (error) {
        console.error(
          `Error loading Impulsa rooms for category ${categoryId}:`,
          error,
        );
        setRooms([]);
      } finally {
        setLoading(false);
      }
    };

    loadRooms();
  }, [token, categoryId]);

  // If no rooms are found after loading, don't render the component
  if (!loading && (!rooms || rooms.length === 0)) {
    return null;
  }

  return (
    <CategoryContainer>
      <Spacer top={5} bottom={5}>
        <Heading level="24-Bold">{title}</Heading>
        <Spacer bottom={2}>
          <TextWrapper>
            <Text>{description}</Text>
          </TextWrapper>
        </Spacer>
        {loading ? (
          <LoadingState />
        ) : (
          <Slider>
            {rooms.map((room) => (
              <RoomCard
                key={room.id}
                id={room.id}
                title={room.title}
                subtitle={room.type}
                flatDescription={room.flatDescription}
                priceText={room.price.displayText}
                image={room.pictures[0]?.url}
                url={room.url}
              />
            ))}
          </Slider>
        )}
      </Spacer>
    </CategoryContainer>
  );
};

export default FeaturedCategory;
