import styled from "styled-components";
import { BADI_COLORS, mediaquery, unitizedPx } from "@badi/badi-components";
import { getPublicAsset } from "base/shared/Utils";

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${unitizedPx(5)} ${unitizedPx(2.5)};
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  width: 100%;
  max-width: ${unitizedPx(150)};
  margin: 0 auto;
  position: relative;
  ${mediaquery.md`
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
  `}
`;

export const FooterSection = styled.div`
  display: flex;

  align-items: start;
  width: 100%;
  padding: ${unitizedPx(2.5)} 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${mediaquery.md` 
    align-items: start;
    width: auto;
    display: flex;
    justify-content: start;
    flex-direction: column;
  `}
  &:first-child,
  &:last-child {
    flex-direction: column;
    ${mediaquery.md`
      flex:1;
      flex-direction: column;
    `}
  }
  &:last-child {
    border-top: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_20};
    ${mediaquery.md`
      border-top: none;
      align-items: flex-end;
      justify-content: flex-end;
      text-align: right;
    `}
  }
  &:first-child {
    align-items: start;
    ${mediaquery.md`
      align-items: flex-start;
      text-align: left;
      justify-content: flex-start;
    `}
    img {
      height: 40px;
      ${mediaquery.md`
        height: 40px;
        
      `}
    }
    & > div {
      display: none;
    }
  }
  &:nth-child(2) {
    ${mediaquery.md`
        padding-right: 90px;
        margin-right: 90px;
        border-right: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_20};
      `}
  }
`;

export const Logo = styled.img`
  height: 76px;
  margin-bottom: 10px;
  ${mediaquery.md`
    margin-bottom: 10px;
    height:96px;
  `}
`;

export const SocialLinks = styled.div`
  display: flex;
  gap: ${unitizedPx(2.5)};
  align-items: center;
`;

export const SocialIcon = styled.a`
  width: ${unitizedPx(3)};
  height: ${unitizedPx(3)};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.6;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
  }

  background-image: ${({ platform }) => {
    switch (platform) {
      case "instagram":
        return `url('${getPublicAsset("/images/landings/impulsa/footer/instagram.svg")}')`;
      case "facebook":
        return `url('${getPublicAsset("/images/landings/impulsa/footer/facebook.svg")}')`;
      case "linkedin":
        return `url('${getPublicAsset("/images/landings/impulsa/footer/linkedin.svg")}')`;
      case "twitter":
        return `url('${getPublicAsset("/images/landings/impulsa/footer/twitter.svg")}')`;
      default:
        return "none";
    }
  }};
`;

export const TermsLink = styled.a`
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_60};
  text-decoration: none;
  font-size: ${unitizedPx(1.75)};
  margin: ${unitizedPx(1.25)} 0;
  margin-bottom: 10px;
  display: block;
  &:hover {
    text-decoration: underline;
  }

  ${mediaquery.md`
    
  `}
`;

export const Copyright = styled.p`
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_60};
  font-size: ${unitizedPx(1.75)};
  margin-top: ${unitizedPx(5)};
  text-align: center;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_20};
  margin: 0;
`;
