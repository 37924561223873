/**
 * Transforms raw API data from /imp_search/rooms endpoint into a structured room model
 */

export class ImpulsaRoomModel {
  constructor(data) {
    if (!data) return null;

    const attributes = data.attributes || {};

    this.id = attributes.room_id || "";
    this.title = attributes.headline?.content || "";
    this.subtitle = attributes.subheading?.content || "";
    this.type = attributes.subheading?.content || "";
    this.flatDescription = this.title || "Badi Impulsa"; // Use headline.content as flatDescription
    this.price = this.parsePrice(attributes);
    this.pictures = this.parsePictures(attributes.pictures);
    this.url = `/room/${this.id}`;
    this.location = attributes.marker || null;
    this.lister = attributes.lister || null;
    this.labels = attributes.labels || [];
  }

  parsePrice(attributes) {
    const priceInfo = attributes.extra_info?.find((info) => info.body?.content);

    if (!priceInfo) return { amount: 0, displayText: "A consultar" };

    const priceText = priceInfo.body?.content || "";
    const amount = parseInt(priceText.replace(/[^0-9]/g, ""), 10) || 0;

    return {
      amount,
      displayText: priceText.includes("/month")
        ? `€${amount}/mes`
        : `€${amount}/mes`,
    };
  }

  parsePictures(picturesData) {
    if (!picturesData || !picturesData.items) return [];

    return picturesData.items.map((pic) => ({
      url: pic.url,
      width_500_url: pic.url,
    }));
  }
}

/**
 * Transform the API response into an array of room models
 */
export const parseImpulsaRooms = (apiResponse) => {
  if (!apiResponse || !apiResponse.data || !apiResponse.data.results) {
    return [];
  }

  return apiResponse.data.results
    .filter((item) => item.type === "room_card")
    .map((item) => new ImpulsaRoomModel(item));
};

export default {
  parseImpulsaRooms,
};
