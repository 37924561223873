import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";
import { HEADER_PARAMS } from "base/shared/tracking-headers/static";
import { parse } from "qs";
import {
  parseSearchCards,
  parseSearchMarkers,
  generateSearchRequestUrl,
  getAllRoomCardsIds,
} from "./utils";

import { SEARCHROOMS_ROOMS_PER_PAGE } from "./static";
const ET_SEARCH_SOURCE_KEY = HEADER_PARAMS.SEARCH_SOURCES.keyVal;

const parseMetadata = (metadata) => {
  if (!metadata) return {};
  const { country_code: countryCode } = metadata.geo;
  return { ...metadata.geo, countryCode };
};

export const fetchMarkers = (
  { token, isLoggedUser },
  filters,
  isImpulsa = false,
) => {
  const {
    BADI_SEARCH_CONTEXT_ROOMS_MARKERS_SERVICE,
    BADI_SEARCH_ROOMS_MARKERS_SERVICE,
    BADI_SEARCH_IMP_CONTEXT_ROOMS_SERVICE,
  } = envVars();

  // Use the Impulsa endpoint for markers if it's an Impulsa search
  if (isImpulsa) {
    const service = BADI_SEARCH_IMP_CONTEXT_ROOMS_SERVICE;
    const staticMarkerPageFilters = {
      ...filters,
      page: 1,
      per: SEARCHROOMS_ROOMS_PER_PAGE.markers,
    };

    const url = generateSearchRequestUrl(service, staticMarkerPageFilters);

    return fetch(url, {
      token,
    }).then(({ data: { results, next_token: nextTokenMarkers } }) => ({
      markers: parseSearchMarkers(results),
      nextTokenMarkers,
    }));
  }

  // Original implementation for non-Impulsa searches
  const service = isLoggedUser
    ? BADI_SEARCH_CONTEXT_ROOMS_MARKERS_SERVICE
    : BADI_SEARCH_ROOMS_MARKERS_SERVICE;

  const staticMarkerPageFilters = {
    ...filters,
    page: 1,
    per: SEARCHROOMS_ROOMS_PER_PAGE.markers,
  };

  const url = generateSearchRequestUrl(service, staticMarkerPageFilters);

  return fetch(url, {
    token,
  }).then(({ data: { results, next_token: nextTokenMarkers } }) => ({
    markers: parseSearchMarkers(results),
    nextTokenMarkers,
  }));
};

const getSearchSourceParamFromQueryString = (queryString) => {
  const query = parse(queryString, { ignoreQueryPrefix: true });
  return query[ET_SEARCH_SOURCE_KEY];
};

const createSearchSourceParam = (searchSource) => {
  const searchSourceFromQueryString = getSearchSourceParamFromQueryString(
    window.location.search,
  );
  const _searchSource = searchSourceFromQueryString || searchSource;
  return _searchSource ? { "search-source": _searchSource } : {};
};

export const generateSearchRequestParams = (
  { token, isLoggedUser },
  { filters, searchSource, isImpulsa = false },
) => {
  const {
    BADI_SEARCH_CONTEXT_ROOMS_SERVICE,
    BADI_SEARCH_ROOMS_SERVICE,
    BADI_SEARCH_IMP_CONTEXT_ROOMS_SERVICE,
  } = envVars();

  // Use the Impulsa endpoint if it's an Impulsa search
  const serviceName = isImpulsa
    ? BADI_SEARCH_IMP_CONTEXT_ROOMS_SERVICE
    : isLoggedUser
      ? BADI_SEARCH_CONTEXT_ROOMS_SERVICE
      : BADI_SEARCH_ROOMS_SERVICE;

  const url = generateSearchRequestUrl(serviceName, filters);

  const additionalParams = createSearchSourceParam(searchSource);
  return [url, { token, additionalParams }];
};

export const fetchRooms = (...args) => {
  const [url, params] = generateSearchRequestParams(...args);
  return fetch(url, params).then(
    ({
      data: {
        results,
        next_token: nextTokenCards,
        previous_token: previousTokenCards,
      },
      metadata,
    }) => ({
      cards: parseSearchCards(results),
      nextTokenCards,
      previousTokenCards,
      metadata: parseMetadata(metadata),
    }),
  );
};

export const fetchRoomsData = async (
  { token, isLoggedUser },
  { filters, searchSource, isImpulsa = false },
) => {
  const { cards, metadata, nextTokenCards, previousTokenCards } =
    await fetchRooms(
      { token, isLoggedUser },
      { filters, searchSource, isImpulsa },
    );

  const excludedRooms = getAllRoomCardsIds(cards);

  const { markers, nextTokenMarkers } = await fetchMarkers(
    { token, isLoggedUser },
    { ...filters, excludedRooms },
    isImpulsa,
  );

  return {
    cards,
    nextTokenCards,
    previousTokenCards,
    markers,
    nextTokenMarkers,
    metadata,
  };
};

export const setRentals = (params, token) => {
  const { BADI_SET_RENTALS_SERVICE } = envVars();
  const serverUrl = getServerUrl(
    BADI_SET_RENTALS_SERVICE.replace("{id}", params.id),
  );

  return fetch(serverUrl, {
    params,
    requiredParams: true,
    method: "POST",
    token,
  });
};

export const getAcceptedRequestUsers = (roomId, token) => {
  if (!roomId) throw new Error("Bad params for request!");
  const { BADI_ACCEPTED_REQUEST_USERS_SERVICE } = envVars();
  const serverUrl = getServerUrl(
    BADI_ACCEPTED_REQUEST_USERS_SERVICE.replace("{id}", roomId),
  );

  return fetch(serverUrl, { token });
};
