import React from "react";
import { getPublicAsset } from "base/shared/Utils";
import { translate } from "base/shared/Localization";
import {
  FooterContainer,
  FooterSection,
  Logo,
  SocialLinks,
  SocialIcon,
  TermsLink,
  Copyright,
  Divider,
} from "./styled-components";

const Footer = () => {
  const badiSocials = [
    { platform: "instagram", url: "https://www.instagram.com/badiapp/" },
    { platform: "facebook", url: "https://www.facebook.com/badiapp/" },
    { platform: "linkedin", url: "https://www.linkedin.com/company/badi/" },
    { platform: "twitter", url: "https://twitter.com/badiapp" },
  ];

  const alefRiveraSocials = [
    {
      platform: "instagram",
      url: "https://www.instagram.com/fundacionalexrivera/",
    },
    {
      platform: "linkedin",
      url: "https://www.linkedin.com/company/fundación-alex-rivera/",
    },
    {
      platform: "facebook",
      url: "https://www.facebook.com/fundacionalexrivera/",
    },
  ];

  const plenaMadridSocials = [
    { platform: "instagram", url: "https://www.instagram.com/plenamadrid/" },
    { platform: "facebook", url: "https://www.facebook.com/plenamadrid" },
    {
      platform: "linkedin",
      url: "https://www.linkedin.com/company/plena-inclusion-madrid/",
    },
    { platform: "twitter", url: "https://twitter.com/PlenaMadrid" },
  ];

  return (
    <FooterContainer>
      <FooterSection>
        <a href="https://badi.com/" target="_blank" rel="noopener noreferrer">
          <Logo
            src={getPublicAsset(
              "/images/landings/impulsa/footer/badi-logo.svg",
            )}
            alt="Badi logo"
          />
        </a>
        <TermsLink
          href="https://badi.com/legal/es/terminos-y-condiciones/ "
          target="_blank"
          rel="noopener noreferrer"
        >
          {translate("impulsa.footer.terms")}
        </TermsLink>
        <SocialLinks>
          {badiSocials.map(({ platform, url }) => (
            <SocialIcon
              key={platform}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              platform={platform}
            />
          ))}
        </SocialLinks>
      </FooterSection>

      <FooterSection>
        <a
          href="https://fundacionalexrivera.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Logo
            src={getPublicAsset(
              "/images/landings/impulsa/footer/fundacionalexrivera.svg",
            )}
            alt="Fundación Alex Rivera"
          />
        </a>
        <SocialLinks>
          {alefRiveraSocials.map(({ platform, url }) => (
            <SocialIcon
              key={platform}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              platform={platform}
            />
          ))}
        </SocialLinks>
      </FooterSection>

      <FooterSection>
        <a
          href="https://plenainclusionmadrid.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Logo
            src={getPublicAsset(
              "/images/landings/impulsa/footer/plenainclusionmadrid.png",
            )}
            alt="Plena Inclusión Madrid logo"
          />
        </a>
        <SocialLinks>
          {plenaMadridSocials.map(({ platform, url }) => (
            <SocialIcon
              key={platform}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              platform={platform}
            />
          ))}
        </SocialLinks>
      </FooterSection>
      <FooterSection>
        <SocialLinks>
          {badiSocials.map(({ platform, url }) => (
            <SocialIcon
              key={platform}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              platform={platform}
            />
          ))}
        </SocialLinks>
        <Copyright>© Badiapp {new Date().getFullYear()} Inc.</Copyright>
      </FooterSection>
    </FooterContainer>
  );
};

export default Footer;
