import { connect } from "react-redux";
import { getToken } from "datamodel/Auth/selectors";
import { getUserId, getDefaultPicture } from "datamodel/User/selectors";
import ImpulsaLanding from "./impulsa-landing";

const mapStateToProps = (state) => ({
  token: getToken(state),
  picture: getDefaultPicture(state),
  isLoggedIn: !!getUserId(state),
});

export default connect(mapStateToProps)(ImpulsaLanding);
