import styled from "styled-components";
import { BADI_COLORS, mediaquery, unitizedPx } from "@badi/badi-components";

export const ButtonWrapper = styled.div`
  max-width: 720px;
`;
export const MainHeadingWrapper = styled.div`
  background-color: #dddcfd;

  width: 100%;
  padding: ${unitizedPx(8)} ${unitizedPx(3)};

  ${mediaquery.md`
      padding: ${unitizedPx(8)} ${unitizedPx(6)};
    `}
`;
export const MainHeadingContainer = styled.div`
  max-width: ${unitizedPx(140)};
  margin: 0 auto;
`;
export const HeaderContainer = styled.div`
  padding: ${unitizedPx(2)} ${unitizedPx(3)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #dddcfd;
`;

export const LoginButton = styled.button`
  background: background-color: #DDDCFD;
  border: 1px solid #000000;
  border-radius: ${unitizedPx(1)};
  cursor: pointer;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${unitizedPx(1.5)} ${unitizedPx(4)};
   background-color: #DDDCFD;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #DDDCFD;
  }
`;

export const UserMenuWrapper = styled.div`
  margin-left: ${unitizedPx(1)};
`;

export const MainSection = styled.div`
  min-height: 80vh;
`;

export const MainHeading = styled.h1`
  font-size: 35px;
  line-height: 44px;
  font-weight: 700;

  max-width: 640px;

  margin-bottom: ${unitizedPx(2)};
  ${mediaquery.md`
    font-size: 50px;
    line-height: 68px;
   
  `}
  em {
    color: ${BADI_COLORS.BRAND.SECONDARY.DEFAULT};
    font-style: normal;
  }
`;

export const Description = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};

  margin-bottom: ${unitizedPx(3)};
  max-width: 720px;

  ${mediaquery.md`
    font-size: 20px;
    line-height: 30px;
  `}
`;

export const SearchButton = styled.button`
  padding: ${unitizedPx(3)} ${unitizedPx(10)};
  background-color: ${BADI_COLORS.BRAND.PRIMARY.DEFAULT};
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  border: none;
  border-radius: ${unitizedPx(0.5)};
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
  padding: ${unitizedPx(2)} ${unitizedPx(6)};

  ${mediaquery.md`
    
    padding: ${unitizedPx(2)} ${unitizedPx(8)}; 
  `}
  &:hover {
    background-color: ${BADI_COLORS.BRAND.PRIMARY.HOVER};
  }
`;

export const CardsContainer = styled.div`
  background: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${unitizedPx(3)};
  margin-top: ${unitizedPx(8)};
  padding-left: ${unitizedPx(3)};
  padding-right: ${unitizedPx(3)};
  padding-top: ${unitizedPx(6)};
  padding-bottom: ${unitizedPx(6)};
  max-width: ${unitizedPx(152)};
  margin: 0 auto;
  overflow-x: auto;
  ${mediaquery.md`
    padding-left:${unitizedPx(6)};
    padding-right:${unitizedPx(6)};
    padding-top:${unitizedPx(8)};
    padding-bottom:${unitizedPx(8)};
  `}
`;

export const Card = styled.div`
  min-width: 240px;
`;

export const CardImage = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  z-index: 10;
`;

export const CardContent = styled.div`
  padding: ${unitizedPx(2)};
  aspect-ratio: 283/438;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: ${unitizedPx(1)};
  overflow: hidden;
  margin-bottom: 28px;
`;

export const CardTitle = styled.h3`
  font-size: 15px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: ${unitizedPx(1)};
  color: white;
  position: relative;
  z-index: 20;
  width: 100%;

  ${mediaquery.md`
    font-size: 25px;
    line-height: 28px;
    text-align: center;
  `}
`;

export const CardDescription = styled.p`
  font-size: 13px;
  line-height: 1.5;

  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_50};
  padding: 0 ${unitizedPx(1)};
  ${mediaquery.md`
    text-align: center;
    font-size: 15px;
    line-height: 1.5;
  `}
`;
