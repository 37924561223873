import styled from "styled-components";
import { BADI_COLORS, mediaquery, unitizedPx } from "@badi/badi-components";

export const RoomCardLink = styled.a`
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
  margin-bottom: ${unitizedPx(2)};
  width: 262px;
  padding-top: 4px;

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  ${mediaquery.md} {
    flex: 0 0 calc(50% - ${unitizedPx(2)});
    margin: ${unitizedPx(1)};
  }

  ${mediaquery.lg} {
    flex: 0 0 calc(25% - ${unitizedPx(2)});
    margin: ${unitizedPx(1)};
  }
`;

export const CategoryContainer = styled.div`
  padding-top: 5px;
`;

export const TextWrapper = styled.div`
  max-width: 500px;
`;

export const CardWrapper = styled.div`
  position: relative;
  transition: transform 0.2s;
  width: 100%;

  &:hover {
    transform: translateY(-${unitizedPx(0.5)});
  }
`;

export const CardImage = styled.div`
  width: 100%;
  aspect-ratio: 1;
  border-radius: ${unitizedPx(2)};
  overflow: hidden;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
`;

export const CardContent = styled.div`
  padding: ${unitizedPx(2)};
  background: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  border-radius: 0 0 ${unitizedPx(2)} ${unitizedPx(2)};
`;

export const PropertyType = styled.div`
  font-size: 14px;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_50};
  margin-bottom: 4px;
`;

export const FlatDescription = styled.h3`
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
  margin-bottom: 4px;
`;

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
  margin-bottom: 0px;
`;

export const PriceRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: ${unitizedPx(1)};
`;

export const PriceColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${unitizedPx(4)};
`;

export const ExpensesColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PriceLabel = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
  margin-bottom: ${unitizedPx(0.5)};
`;

export const ExpensesLabel = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
  margin-bottom: ${unitizedPx(0.5)};
`;

export const Price = styled.div`
  font-size: 13px;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
`;

export const Expenses = styled.div`
  font-size: 13px;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
`;

export const LoadingState = styled.div`
  width: 100%;
  height: 245px;
`;

export const SectionTitle = styled.h2`
  font-size: 32px;
  font-weight: 700;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
  margin-bottom: ${unitizedPx(2)};
`;

export const SliderWrapper = styled.div`
  margin: 0 -${unitizedPx(1)};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
