import fetch from "base/shared/fetch-data";
import envVars from "base/shared/Env";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";
import { parseImpulsaRooms } from "./models/room-model";

export const fetchImpulsaCollection = async ({ token }) => {
  try {
    const { BADI_SEARCH_IMP_CONTEXT_ROOMS_SERVICE } = envVars();
    const serverUrl = getServerUrl(BADI_SEARCH_IMP_CONTEXT_ROOMS_SERVICE);

    const response = await fetch(serverUrl, { token });
    const rooms = parseImpulsaRooms(response);

    return { rooms, hasRooms: rooms.length > 0 };
  } catch (error) {
    console.error("Error fetching Impulsa rooms:", error);
    return { rooms: [], hasRooms: false };
  }
};

export const fetchImpulsaRoomsByCategory = async ({ token, categoryId }) => {
  try {
    const { BADI_SEARCH_IMP_CONTEXT_ROOMS_SERVICE } = envVars();
    const baseUrl = getServerUrl(BADI_SEARCH_IMP_CONTEXT_ROOMS_SERVICE);
    const serverUrl = `${baseUrl}?badi_impact_room_category_id=${categoryId}`;

    const response = await fetch(serverUrl, { token });
    const rooms = parseImpulsaRooms(response);

    return { rooms, hasRooms: rooms.length > 0 };
  } catch (error) {
    console.error(
      `Error fetching Impulsa rooms for category ${categoryId}:`,
      error,
    );
    return { rooms: [], hasRooms: false };
  }
};
