import React from "react";
import { Flex, Heading, LinkButton } from "@badi/badi-components";
import { Button, BUTTON_TYPES, colors, BADI_COLORS } from "app/badi-components";
import { translate } from "base/shared/Localization";

import { getInboxPath } from "Sections/inbox/routes";
import EditRoomButton from "./components/edit-room-button";
import CheckBadiHomeAvailabilityButton from "./check-badi-home-availability-button";
import ChatButton from "./components/chat-button";
import { BookingWrapper } from "./styled-components";
import { getActions } from "./utils";
import { ROOM_ACTIONS } from "./constants";

const SM_FLEX_STYLES = { alignItems: "flexEnd", gutter: 1 };

const RoomAction = ({
  room,
  messageEnquiryFetched,
  onEnquiryClick,
  openFastTrackInformationModal,
  isUserWithFastTrack,
  isBadiHome,
}) => {
  const {
    allowToContact,
    allowToEdit,
    id,
    inboxConnectionId,
    status,
    checkAvailabilityUrl,
  } = room;

  if (!id) {
    return null;
  }

  const actions = getActions({
    room,
    messageEnquiryFetched,
    allowToContact,
    allowToChat: false,
  });

  if (isBadiHome) {
    if (actions.includes(ROOM_ACTIONS.CHAT)) {
      return <ChatButton connectionId={inboxConnectionId} />;
    } else {
      return (
        <Flex
          alignItems="flexEnd"
          as={BookingWrapper}
          gutter={1}
          sm={SM_FLEX_STYLES}
        >
          <Flex direction="column" gutter={1}>
            <Button
              basis="50%"
              data-qa="apply-enquiry-button"
              fullWidth={true}
              onClick={() => {
                onEnquiryClick({ roomId: room.id }, true);
              }}
              variant={BUTTON_TYPES.INFO}
            >
              {translate("enquiry.apply.send-button")}
            </Button>
          </Flex>
        </Flex>
      );
    }
  }

  return (
    <Flex
      alignItems="flexEnd"
      as={BookingWrapper}
      gutter={1}
      sm={SM_FLEX_STYLES}
    >
      {actions.map((action) => {
        switch (action) {
          case ROOM_ACTIONS.BOOKED:
            return (
              <Heading
                color={BADI_COLORS.NEUTRAL.NEUTRAL_50}
                data-qa="room-action-booked"
                key={action}
                level="16-Medium"
              >
                {translate("room.detail.header.booked")}
              </Heading>
            );
          case ROOM_ACTIONS.PENDING:
            return (
              <Heading
                color={colors.yellow}
                data-qa="room-action-pending"
                key={action}
                level="16-Medium"
              >
                {translate("room.detail.header.pending")}
              </Heading>
            );
          case ROOM_ACTIONS.SETTINGS:
            return (
              <EditRoomButton
                disabled={!allowToEdit}
                key={action}
                roomId={id}
                roomStatus={status}
              />
            );
          case ROOM_ACTIONS.REQUESTED:
            return (
              <Button
                dataQa="room-action-requested"
                disabled={true}
                key={action}
                variant={BUTTON_TYPES.SECONDARY}
              >
                {translate("room.detail.header.requested")}
              </Button>
            );
          case ROOM_ACTIONS.RENTED:
            return (
              <Heading
                color={BADI_COLORS.NEUTRAL.NEUTRAL_50}
                data-qa="room-action-rented"
                key={action}
                level="16-Medium"
              >
                {translate("room.detail.header.seeker.rented")}
              </Heading>
            );
          case ROOM_ACTIONS.CONTACT:
            return (
              <Flex direction="column" key={action} gutter={1}>
                <Button
                  basis="50%"
                  data-qa="simplify-enquiry-button"
                  fullWidth={true}
                  key="simplify-enquiry-button"
                  onClick={() => onEnquiryClick({ roomId: room.id })}
                  variant={
                    isUserWithFastTrack
                      ? BUTTON_TYPES.GOLD_GRADIENT
                      : BUTTON_TYPES.PRIMARY
                  }
                >
                  {isUserWithFastTrack
                    ? translate("enquiry.fast-track.send-button")
                    : translate("room.detail.header.seeker.chat")}
                </Button>
                {!isUserWithFastTrack && !isBadiHome && (
                  <Button
                    basis="50%"
                    data-qa="get-fast-track"
                    color="black"
                    background={BADI_COLORS.GRADIENT.GRADIENT_02}
                    hoverFilter="brightness(95%)"
                    fullWidth={true}
                    key="open-fast-track-information"
                    onClick={openFastTrackInformationModal}
                  >
                    Fast-Track
                  </Button>
                )}
                {checkAvailabilityUrl && (
                  <CheckBadiHomeAvailabilityButton
                    href={checkAvailabilityUrl}
                  />
                )}
              </Flex>
            );
          case ROOM_ACTIONS.CHAT:
            return <ChatButton connectionId={inboxConnectionId} key={action} />;
          case ROOM_ACTIONS.INBOX:
            return (
              <LinkButton
                href={getInboxPath()}
                key={action}
                variant={BUTTON_TYPES.SECONDARY}
              >
                {translate("room.detail.header.inbox")}
              </LinkButton>
            );
          default:
            return (
              <Heading
                color={BADI_COLORS.NEUTRAL.NEUTRAL_50}
                data-qa="room-action-not-available"
                key={action}
                level="16-Medium"
              >
                {translate("room.detail.header.not_available")}
              </Heading>
            );
        }
      })}
    </Flex>
  );
};

RoomAction.defaultProps = {
  isBadiHome: false,
};

export default RoomAction;
